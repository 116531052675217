import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
})

export default function PPCardWithButton(props) {
  const classes = useStyles()

  function update() {
    //console.log(props.values)
    props.onClick(props.question, props.answer)
  }

  return (
    <Card
      className={
        props.orderData[props.question] === props.answer
          ? classes.root + " pp-active height-100 mw-none"
          : classes.root + " height-100 mw-none"
      }
      variant="outlined"
      onClick={() => update() /**/}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt=""
          height="140"
          image={props.imageUrl}
          title="Contemplative Reptile"
        />

        <div className={props.labelType ? props.labelType : "hidden"}>
          {props.labelContent}
        </div>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.headerText}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.descriptionText}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

PPCardWithButton.defaultProps = {
  orderData: {},
}
