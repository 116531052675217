import React from "react"
import PPCardWithButton from "../components/card-with-button"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"

import Button from "@material-ui/core/Button"
import scrollTo from "gatsby-plugin-smoothscroll"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  containerTest: {
    paddingTop: "40px",
  },
  optionsContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
}))

export default function Home() {
  const classes = useStyles()

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    orderData: {},
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
    if (prop === "amount") {
      handleOptionSelect("tip-amount", event.target.value)
    }
  }

  function handleOptionSelect(question, answer) {
    //question is a number
    values.orderData[question] = answer
    console.log(values.orderData)
    if (question !== "tip-amount") {
      setValues({ ...values, orderData: values.orderData })
    }

    if (question === "coffee-type") {
      scrollTo("#select-location")
    }
    if (question === "location") {
      scrollTo("#user-data")
    }
  }

  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Click For Coffee by Purple Panda</title>
        <link rel="canonical" href="https://clickforcoffee.purplepanda.be" />
      </Helmet>

      <Container maxWidth="lg" className={classes.containerTest}>
        <img
          className="cfc-logo"
          src="./images/panda-icon-small.png"
          alt="click for coffee logo purple panda"
        />
        <small className="small-label">You clicked for coffee!</small>
        <h1>Jij brengt het idee, wij brengen de koffie.</h1>
        <p className="pp-small">Confused? 🧐 Klik hier</p>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Hoe werkt het?
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Jij brengt het idee, wij brengen de koffie
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Al jaren gebeurt het dat succesvolle partnerships hun begin kennen
              met "kom eens een koffie drinken". ☕️ Met Click For Coffee™ wilt
              Purple Panda dit naar een hoger niveau tillen.
              <br />
              <br />
              Kies jouw favoriete koffie, kies waar je deze wilt drinken en
              let's chat! Onze digitale expert komt bij jou langs met je gekozen
              koffie alsook met ijzersterk digitaal advies voor jouw bedrijf.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              HMMMM.. COFFEE
            </Button>
            <Button autoFocus onClick={handleClose} color="secondary">
              SLUIT BERICHT
            </Button>
          </DialogActions>
        </Dialog>

        <div id="select-coffee">
          <div className="pp-spacer"></div>

          <Typography variant="h5" component="h2" gutterBottom>
            1. Selecteer je koffie
          </Typography>

          <div className={classes.optionsContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Pads"
                  imageUrl="./images/pads.jpg"
                  question="coffee-type"
                  answer="pads"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Capsules"
                  imageUrl="./images/capsules.jpg"
                  question="coffee-type"
                  answer="capsules"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Bonen"
                  imageUrl="./images/bonen.png"
                  question="coffee-type"
                  answer="bonen"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="pp-spacer"></div>

        <div
          id="select-location"
          className={
            values.orderData["coffee-type"] === undefined ? "pp-blocked" : ""
          }
        >
          <Typography variant="h5" component="h2" gutterBottom>
            2. Selecteer locatie
          </Typography>
          <div className={classes.optionsContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Opdrinken ter plekke"
                  descriptionText="Kom jij naar ons kantoor in Zuid-Antwerpen? Wat fijn!"
                  imageUrl="./images/office.jpg"
                  question="location"
                  answer="opdrinken-ter-plekke"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                  labelType="pp-label label-green"
                  labelContent="inclusief digitale consultatie"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Laten Leveren"
                  descriptionText="Lekker efficiënt! Dat snappen we. De panda's springen wel binnen op jouw kantoor!"
                  imageUrl="./images/delivery-foto.png"
                  question="location"
                  answer="laten-leveren"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                  labelType="pp-label label-green"
                  labelContent="inclusief digitale consultatie"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PPCardWithButton
                  headerText="Afhalen"
                  descriptionText="Kom je het liever ophalen om zelf thuis op te drinken? Ook goed!"
                  imageUrl="./images/take-out-3.jpg"
                  question="location"
                  answer="afhalen"
                  orderData={values.orderData}
                  onClick={handleOptionSelect}
                  labelType="pp-label label-brown"
                  labelContent="gewoon koffie"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="pp-spacer"></div>

        <div
          id="user-data"
          className={
            values.orderData["location"] === undefined ? "pp-blocked" : ""
          }
        >
          <Typography variant="h5" component="h2" gutterBottom>
            3. Jouw gegevens
          </Typography>

          <br />
          <br />

          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            method="post"
            action="https://getform.io/f/c03a0c69-231a-407b-86a2-d6b1f84a14f6"
          >
            <input
              id="form-location"
              name="form-location"
              type="hidden"
              value={values.orderData["location"] + " /"}
            />
            <input
              id="form-coffee-type"
              name="form-coffee-type"
              type="hidden"
              value={values.orderData["coffee-type"] + " /"}
            />
            <Grid container spacing={3} className="pp-form-container">
              <Grid item xs={12} sm={12}>
                <p className="pp-small">
                  Laat je gegevens achter en we contacteren jou om je gratis
                  koffie levering af te stemmen.
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    id="form-naam"
                    label="Naam"
                    defaultValue=""
                    name="form-naam"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    id="form-bedrijf"
                    label="Bedrijf"
                    defaultValue=""
                    name="form-bedrijf"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    id="form-tel"
                    label="Telefoon nummer"
                    defaultValue=""
                    name="form-tel"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    id="form-email"
                    label="E-mail"
                    defaultValue=""
                    name="form-email"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  id="form-adres"
                  label="Adres van je kantoor (indien levering)"
                  defaultValue=""
                  name="form-adres"
                  className="w-100"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  id="form-opmerkingen"
                  label="Extra Opmerkingen"
                  multiline
                  rows={3}
                  defaultValue=""
                  name="form-opmerkingen"
                  className="w-100"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <p>Tip (Optioneel)</p>
                <p className="pp-small">
                  Hou onze medewerkers gemotiveerd met een kleine tip, de koffie
                  blijft gratis! Tip wordt gefactureerd na consumptie.
                </p>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="form-tip">Amount</InputLabel>
                  <OutlinedInput
                    id="form-tip"
                    value={values.amount}
                    onChange={handleChange("amount")}
                    startAdornment={
                      <InputAdornment position="start">€</InputAdornment>
                    }
                    labelWidth={60}
                    name="form-tip"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="large"
                >
                  Plaats bestelling
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>

        <div className="pp-spacer"></div>
        <p className="pp-small">Be proud, have fun.</p>
      </Container>
    </div>
  )
}
